import React from 'react'
import Layout from '../../components/Layout'
import VoucherActivation from '../../components/vouchers/VoucherActivation'

const CDN_ROOT = 'https://mfg-home.b-cdn.net/'

const VoucherPage = (props) => {
  return (
    <Layout CDN_ROOT={CDN_ROOT}>
      <div>hello world</div>
      <VoucherActivation {...props} />
    </Layout>
  )
}

export default VoucherPage
