import React from 'react'
import * as styles from './Voucher.module.css'

function parseQuery(url) {
  const queryObject = {}
  if (url.indexOf('?') < 0) {
    return queryObject
  }
  const parameters = url.split('?')[1]
  parameters.split('&').forEach((element) => {
    if (element.indexOf('=') < 0) {
      return queryObject
    }
    const [key, value] = element.split('=')
    queryObject[key] = value
  })
  return queryObject
}

const VoucherActivation = (props) => {
  const { location } = props
  const { code } = parseQuery(location.search)

  return (
    <div className={styles.voucherContainer}>
      <h1>{'Voucher code'}</h1>

      {code ? (
        <>
          <div className={styles.code}>
            <code>{code}</code>
          </div>
          <a href={`unkilled://voucher=${code}`}>
            <div className={styles.button}>{'Activate in game'} </div>
          </a>
        </>
      ) : (
        <div className={styles.error}>
          No voucher code. Try loading the voucher link again.
        </div>
      )}
    </div>
  )
}

export default VoucherActivation
